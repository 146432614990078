import { Component, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ServicesModalComponent } from './services-modal/services-modal.component';
import { environment } from '../environments/environment';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { EtaSettingsService } from '../services/eta-settings.service';
import { loadStripe } from "@stripe/stripe-js";
import { RedfinCouponComponent } from './redfin-coupon/redfin-coupon.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { BreakpointService } from '../services/breakpoint.service';
import { Title } from '@angular/platform-browser';
import { BingService } from '../services/bing.service';
import { PixelService } from '../services/pixel.service';
import moment from 'moment-timezone';
import { Options } from "sortablejs";
import { indexOf } from 'lodash';
import { UpdatePhoneComponent } from '../components/update-phone/update-phone.component';
import { UpdateCompanyComponent } from '../components/update-company/update-company.component';
import { firstValueFrom } from 'rxjs';





@Component({
	selector: 'app-new-project',
	templateUrl: './new-project.component.html',
	styleUrls: ['./new-project.component.scss'],
	animations: [
		trigger('expandCollapse', [
			state('collapsed', style({ maxHeight: '0px', overflow: 'hidden', padding: '0px' })),
			state('expanded', style({ maxHeight: '500px', overflow: 'hidden', padding: '*' })),
			transition('collapsed => expanded', [
				animate('100ms linear')
			])
		])
	]
})
export class NewProjectComponent {
	public project: any;
	public loading: boolean = true;
	public processingProject: boolean = false;
	public currentStep: number = 2;
	public indicatorWidth: string = "";
	public stepWidth: number = 0;
	public projectName: string = "";
	public projectGuid: string | null = "";
	public uploadProgress: { [key: string]: number } = {};
	public totalProgress: number = 0;
	public completedFiles: number = 0;
	public uploading: boolean = false;
	public errorMessages: any = [];
	categoryMapping = {
		997: { text: 'Upon Review', tooltip: 'unlimited' },
		24: { text: '24 hours', tooltip: 'v24' },
		48: { text: '48 hours', tooltip: 'v48' },
		23: { text: '8 to 24 hours', tooltip: 'v8_24' },
		8: { text: '8 hours', tooltip: 'v8' },
		4: { text: '4 to 8 hours', tooltip: 'v4' },
		'default': { text: '{{ category }} hours', tooltip: 'hl' }
	};
	public etaSettings: any;
	public limitValue: number = 0;
	public priceAfterLimit: number = 0;
	public alwaysSameEta: any;
	public rushSettings: any;
	public serviceCounts: any;
	public eta: any;
	public isWeekend: boolean = false;
	public maxEta: number = 0;
	private stripeAPI: any;
	public expandedRequest: string = "";
	public couponEnabled: boolean = false;
	public couponCode: string = "";
	public user: any;
	// Pp === 'Phone Portrait'
	public isPp: boolean = false;
	public ppCheckout: boolean = false;
	public couponError: boolean = false;
	public dontShowAgain: boolean = false;
	public dragging: boolean = false;
	public totalDiscount: number = 0;
	loadedImages: Set<any> = new Set();
	public backHome: boolean = false;
	public processingUploads: boolean = false;
	public couponDiscount: number = 0;
	public wallet: any;
	public walletFundsApplied: number = 0;
	public vsCreditsApplied: number = 0;
	public projectType: string | null = "";
	public noProjects: boolean = false;
	@ViewChild('12MonthsOfferDialog') twelveMonthsOfferDialog!: TemplateRef<any>;
	public specialOfferValid: boolean = false;
	public continuation: any = null;
	public dragOptions: Options = {
		delay: 500,
		delayOnTouchOnly: true,
		onMove: (event: any) => {
			if (event.related.classList.contains('non-draggable')) {
				return false;
			} else {
				return true;
			}
		},
		onUpdate: this.onUpdate.bind(this),
		filter: '.non-draggable',
		preventOnFilter: false
	};
	public tasks: any = [];
	public roomTypes: any = [];
	public furnitureStyles: any = [];
	public floorplan2Requests: any = [];
	public floorplanBumpTask: any = {};
	public fpBump: boolean = false;
	public fpBumpPrice = 30;

	constructor(
		private router: Router,
		private api: ApiService,
		public dialog: MatDialog,
		private location: Location,
		private route: ActivatedRoute,
		private etaSettingsService: EtaSettingsService,
		public breakpointService: BreakpointService,
		private titleService: Title,
		private bing: BingService,
		private pixel: PixelService
	) {
		this.etaSettingsService.getEtaSettings.subscribe(data => {
			this.etaSettings = data;
		});
		this.etaSettingsService.getLimitPrice.subscribe(data => {
			if (data) {
				const limitPricesValues = data[0].value.split("|");
				this.limitValue = parseInt(limitPricesValues[0], 10);
				this.priceAfterLimit = parseInt(limitPricesValues[1], 10);
			}
		});
		this.etaSettingsService.getAlwaysSameEta.subscribe(data => {
			this.alwaysSameEta = data;
		});
		this.etaSettingsService.getRushSettings.subscribe(data => {
			this.rushSettings = data;
		});
		this.user = localStorage.getItem("user");
		this.user = JSON.parse(this.user);
	}

	async ngOnInit() {
		this.route.data.subscribe((data) => {
			this.tasks = data['data'][0];
			this.roomTypes = data['data'][1];
			this.furnitureStyles = data['data'][2];
			this.wallet = data['data'][3];
		});
		this.floorplanBumpTask = this.tasks.filter((task: any) => task.name === '3D Floor Plan')[0];
		this.projectGuid = this.route.snapshot.paramMap.get('guid');
		this.projectType = this.route.snapshot.queryParamMap.get('type');
		this.noProjects = this.route.snapshot.queryParamMap.get('noProjects') === 'true';
		this.continuation = JSON.parse(decodeURIComponent(this.route.snapshot.queryParamMap.get('continuation') || 'null'));
		if (this.continuation) {
			this.projectName = this.continuation.name.endsWith(' - additional') ? this.continuation.name : `${this.continuation.name} - additional`;
		}
		if (!this.noProjects && !this.projectGuid) {
			this.api.get("projects/v2").then((projects) => {
				if (projects.length === 0) {
					this.noProjects = true;
				}
			})
		}
		this.dontShowAgain = localStorage.getItem("dontShowAgain") === "true";
		this.configureSteps();
		this.titleService.setTitle('New Project');
		this.router.navigate([], { queryParams: {} });
		this.api.get("coupons/domain").then((res) => {
			this.couponEnabled = !!res;
		});

		if (this.projectGuid) {
			this.currentStep = 4;
			this.indicatorWidth = this.getIndicatorWidth();
			this.getProject();
		} else {
			this.loading = false;
		}
		await this.seesSpecialOffer();
	}

	isFp(): boolean {
		return this.projectType === 'floorplan';
	}

	@HostListener('window:orientationchange', ['$event'])
	onOrientationChange(event: Event) {
		if (event.target) {
			if (this.breakpointService.currentBreakpointClass === 'is-phone-portrait' || this.breakpointService.currentBreakpointClass === 'is-phone-landscape') {
				const target = event.target as Window;
				if (target.screen.orientation.angle === 0 || target.screen.orientation.angle === 180) {
					this.isPp = true;
					this.indicatorWidth = this.getIndicatorWidth();
				} else {
					this.isPp = false;
					if (this.currentStep === 5) {
						this.currentStep = 4;
					}
					this.indicatorWidth = this.getIndicatorWidth();
				}
			}
		}
	}

	configureSteps() {
		if (this.continuation?.guid) {
			this.currentStep = 3;
			if (this.breakpointService.currentBreakpointClass === 'is-phone-portrait') {
				this.isPp = true;
				this.indicatorWidth = '40%';
				this.stepWidth = 40;
			} else {
				this.isPp = false;
				this.indicatorWidth = '50%';
				this.stepWidth = 50;
			}
			return;
		}
		if (!this.dontShowAgain || this.isFp()) {
			this.currentStep = 1;
			if (this.breakpointService.currentBreakpointClass === 'is-phone-portrait') {
				this.isPp = true;
				this.indicatorWidth = '20%';
				this.stepWidth = 20;
			} else {
				this.isPp = false;
				this.indicatorWidth = '25%';
				this.stepWidth = 25;
			}
		} else {
			this.currentStep = 2;
			if (this.breakpointService.currentBreakpointClass === 'is-phone-portrait') {
				this.isPp = true;
				this.indicatorWidth = '25%';
				this.stepWidth = 25;
			} else {
				this.isPp = false;
				this.indicatorWidth = '33.3%';
				this.stepWidth = 33.3;
			}
		}
	}

	async ngAfterViewInit() {
		this.stripeAPI = await loadStripe(environment.stripeKey);
	}

	handleCouponKeydown(event: KeyboardEvent) {
		if (!this.couponCode) {
			return;
		}
		if (event.key === 'Enter') {
			this.checkBeforeApplyingCoupon();
		}
	}

	async checkBeforeApplyingCoupon() {
		if (this.couponCode === 'SOTHEBYS100') {
			const confirmed = await this.sothebysConfirmation();

			if (!confirmed) {
				return;
			} else {
				this.applyCouponApi();
			}
		} else if (this.couponCode.toLowerCase() === 'redfin') {
			await this.applyCouponApi();
			let regions: any[] = [];

			for (let [key, value] of Object.entries(this.project.discount.markets)) {
				let region: any = {
					name: key,
					markets: value,
				}
				regions.push(region);
			}

			if (regions?.length > 0) {
				const result = await this.redfinExtraInfo(regions);
				if (!result) {
					return;
				} else {
					await this.saveAditionalInfos({ 'Listing ID': result.listingId, 'Business Area/Market': result.market });
				}
			}

		} else {
			this.applyCouponApi()
		}
	}

	async applyCouponApi(): Promise<void> {
		await this.api
			.patch(
				`project/${this.project.guid}/coupon/${this.couponCode}/apply`
			)
			.then(
				(project) => {
					this.project = project;
					this.getTotalForProject();
					this.getTotalDiscount();
					this.calculateCreditsApplied();

				}
			).catch(() => {
				this.couponError = true;
				setTimeout(() => {
					this.couponError = false;
				}, 3000)
			});
	}

	async redfinExtraInfo(regions: any): Promise<any> {
		let dialogRef = this.dialog.open(RedfinCouponComponent, {
			data: {
				regions,
			},
			disableClose: true,
		});
		return new Promise<any>((resolve) => {
			dialogRef.afterClosed().subscribe((result: any) => {
				resolve(result);
			});
		});
	}

	async saveAditionalInfos(values: any) {
		this.api.put(`project/${this.project.guid}/additional`, {
			additionalFields: JSON.stringify(values),
		})
	}

	async sothebysConfirmation(): Promise<boolean> {
		let dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: {
				icon: 'warning',
				message: "Please confirm eligibility",
				moreInfo: "This coupon code is exclusively available to authorized Sotheby's International Realty agents who are part of the East Side and Downtown offices. Please confirm your eligibility to use the coupon code before proceeding.",
				confirm: {
					title: "Confirm",
				},
				reject: {
					title: "Cancel",
				}
			}
		});

		return new Promise<boolean>((resolve) => {
			dialogRef.afterClosed().subscribe((result: any) => {
				resolve(!!result);
			});
		});
	}

	getTotalDiscount(): void {
		let percentDiscount = this.project.discount?.discount;
		let totalDiscount = 0;
		let couponDiscount = 0;
		if (percentDiscount) {
			totalDiscount += this.project.total / 100 * percentDiscount;
			couponDiscount += this.project.total / 100 * percentDiscount;
		}
		if (this.seesBulkDiscount()) {
			if (this.serviceCounts && this.serviceCounts["Virtual Staging"]) {
				if (this.serviceCounts["Virtual Staging"] > this.limitValue) {
					totalDiscount += Math.min(this.serviceCounts["Virtual Staging"] - 12, 3) * 24;
				}
				if (this.serviceCounts["Virtual Staging"] > this.limitValue + 3) {
					totalDiscount += (this.serviceCounts["Virtual Staging"] - 15) * (24 - 19);
				}
			}
		}
		this.totalDiscount = totalDiscount;
		this.couponDiscount = couponDiscount;
	}

	seesBulkDiscount(): boolean {
		return this.project && !this.project.discount;
	}

	updateSteps() {
		for (let request of this.project.service_requests) {
			if (request.asset) {
				request.asset.error = "";
			}
		}
		this.projectName = this.project.name;
		this.indicatorWidth = this.getIndicatorWidth();
	}

	parseInt(value: string): number {
		return parseInt(value, 10);
	}

	async getProject(): Promise<void> {
		const response = await this.api.patch(
			`project/${this.projectGuid}/calculate`
		);
		this.floorplan2Requests = await this.api.get(`project/${this.projectGuid}/floorplans`);

		if (response.paid) {
			this.router.navigate([`/projects/${this.projectGuid}/delivery`]);
			return;
		}
		this.project = response;
		if (this.project.type === "floorplan") {
			this.projectType = "floorplan";
		}
		if (this.floorplan2Requests.length > 0) {
			this.project.service_requests = this.project.service_requests.concat(this.floorplan2Requests);
		}
		this.projectName = this.project.name;
		for (let serviceRequest of this.project.service_requests) {
			for (let service of serviceRequest.service_request_tasks) {
				if (typeof service.data === 'string') {
					try {
						service.data = JSON.parse(service.data);
					} catch (e) {
						console.error('JSON parsing failed for service:', service.name, 'Error:', e);
					}
				}
			}
		}
		this.applyAiCoupon();
		this.eta = this.getEta();
		this.updateSteps();
		this.checkFpBump();
		this.getTotalForProject();
		this.titleService.setTitle(`${this.project?.name || this.projectName} - Summary`);
		this.getTotalDiscount();
		this.calculateCreditsApplied();

		this.project.service_requests.sort((a: any, b: any) => a.sort - b.sort);
		this.loading = false;
	}


	goHome(): void {
		this.router.navigate(["/projects"]);
	}

	goBack(): void {
		if (this.currentStep === 4 && this.isPp && this.ppCheckout) {
			this.ppCheckout = false;
			this.indicatorWidth = this.getIndicatorWidth();
			return;
		} else if (this.currentStep === 2 && this.dontShowAgain) {
			this.goHome();
			return;
		}
		this.currentStep += -1;
		this.indicatorWidth = this.getIndicatorWidth();
		if (this.currentStep === 0) {
			this.goHome();
		}
	}

	goNext(): void {
		if (this.checkIfNextStepDisabled()) {
			return;
		}
		if (this.currentStep === 4 && !this.isPp) {
			this.payProject();
			return;
		} else if (this.currentStep === 4 && this.isPp && this.ppCheckout) {
			this.payProject();
			return;
		} else if (this.currentStep === 4 && this.isPp) {
			this.ppCheckout = true;
			this.eta = this.getEta();
			this.countServices();
			this.indicatorWidth = this.getIndicatorWidth();
			return;
		}
		if (this.currentStep === 1 && this.dontShowAgain) {
			localStorage.setItem("dontShowAgain", "true");
		}
		this.currentStep += 1;
		this.indicatorWidth = this.getIndicatorWidth();
		if (this.currentStep === 4) {
			this.location.go(`/projects/${this.project.guid}/summary`);
			this.titleService.setTitle(`${this.project?.name || this.projectName} - Summary`);
		}
	}

	getNextTitle() {
		if ((this.currentStep === 4 && !this.isPp) || (this.currentStep === 4 && this.isPp && this.ppCheckout)) {
			if (this.couponEnabled && this.project.discount) {
				return "Start Project";
			} else {
				if (this.breakpointService.currentBreakpointClass === 'is-phone-portrait') {
					return "Checkout";
				} else {
					return "Checkout & Start Project";
				}
			}
		} else {
			return "Next"
		}
	}

	getIndicatorWidth(): string {
		let multiplier = this.isPp ? 20 : 25;
		let bonusStep = this.isPp && this.ppCheckout ? 1 : 0;
		return (this.currentStep + bonusStep) * multiplier + "%";
	}

	isHeicFile(file: any): boolean {
		return file.name.toLowerCase().endsWith('.heic');
	}

	async onFileSelected(event: Event): Promise<void> {
		const input = event.target as HTMLInputElement;
		if (!this.project) {
			await this.createProject();
		}
		let totalFiles = input.files ? input.files.length : 0;
		let validFiles: any = [];

		if (input.files && totalFiles > 0) {
			for (let i = 0; i < totalFiles; i++) {
				const file = input.files[i];
				this.uploading = true;
				if (file.size > 125 * 1024 * 1024) {
					this.errorMessages.push(`The file ${file.name} is too large. The maximum file size is 125MB.`);
					this.cleanErrorMessages();
					if (validFiles === 0) {
						this.totalProgress = 0;
						this.completedFiles = 0;
						this.uploadProgress = {};

						this.uploading = false;
					}
					continue;
				}

				if (!file.type.startsWith('image/') && !this.isHeicFile(file)) {
					this.errorMessages.push(`The file ${file.name} is not an image.`);
					this.cleanErrorMessages();
					if (validFiles === 0) {
						this.totalProgress = 0;
						this.completedFiles = 0;
						this.uploadProgress = {};

						this.uploading = false;
					}
					continue;
				}

				if (!['image/x-png', 'image/gif', 'image/jpeg', 'image/png', 'image/jpg', 'image/webp', 'image/tiff', 'image/heic'].includes(file.type) && !this.isHeicFile(file)) {
					this.errorMessages.push(`The file type of ${file.name} is unsupported.`);
					this.cleanErrorMessages();
					if (validFiles === 0) {
						this.totalProgress = 0;
						this.completedFiles = 0;
						this.uploadProgress = {};

						this.uploading = false;
					}
					continue;
				}

				validFiles.push(file);
			}
			if (validFiles.length > 0) {
				for (let i = 0; i < validFiles.length; i++) {
					const formData = new FormData();
					let sanitizedFilename = this.sanitizeFilename(validFiles[i].name);
					formData.append('imageFile', validFiles[i], sanitizedFilename);
					formData.append('index', (i + 1).toString());

					try {
						await this.uploadFile(formData, validFiles[i].name, validFiles.length);
					} catch (error) {
						// Handle the error appropriately if needed
					}
				}
			}

			// Reset total progress if all files have been uploaded
			if (this.completedFiles === validFiles.length) {
				this.totalProgress = 0;
				this.completedFiles = 0;
				this.uploadProgress = {};
				await this.getUpdatedProject();
				if (this.currentStep === 3) {
					this.goNext();
					this.noProjects = false;
					this.seesSpecialOffer();
				}
				this.uploading = false;
			}
		}
	}

	private async uploadFile(formData: FormData, fileName: string, validFiles: number): Promise<void> {
		return new Promise((resolve, reject) => {
			this.api.upload(`project/${this.project.guid}/serviceRequest`, formData).subscribe({
				next: (event) => {
					if (event.type === HttpEventType.UploadProgress) {
						let progress: number = 0;
						if (event.total) {
							progress = 100 / event.total * event.loaded;
						}
						this.uploadProgress[fileName] = progress;
						let accumulatedProgress = 0;
						for (let key in this.uploadProgress) {
							accumulatedProgress += this.uploadProgress[key];
						}
						this.totalProgress = Math.round(accumulatedProgress / validFiles);

					} else if (event instanceof HttpResponse) {
						// File uploaded successfully
						this.completedFiles++;
						resolve();
					}
				},
				error: (error) => {
					// Handle upload error
					this.errorMessages.push(`Failed to upload ${fileName}. Please try again.`);
					this.cleanErrorMessages();
					reject(error);
				},
				complete: () => {
					// Optionally handle completion if needed
				}
			});
		});
	}




	sanitizeFilename(filename: string) {
		let extension = filename.split('.').pop();
		extension = extension ? '.' + extension : '';

		let nameWithoutExtension = filename.replace(extension, '');
		const sanitized = nameWithoutExtension
			.replace(/[^a-zA-Z0-9-_.\s]/g, '')
			.replace(/\s+/g, '_')
			.substring(0, 150 - extension.length) + extension;

		return sanitized.toLowerCase();
	}

	getDropZoneStyle() {
		const progress = this.totalProgress || 0;
		return {
			'height': `${progress}%`,
		}
	}

	getBumpZoneStyle() {
		const progress = this.totalProgress || 0;
		return {
			'width': `${progress}%`,
		}
	}

	getAddMoreStyle() {
		const progress = this.totalProgress || 0;
		return {
			'height': `${progress}%`
		};
	}

	checkIfNextStepDisabled(): boolean {
		if (this.currentStep === 1) {
			return false;
		}
		if (this.currentStep === 2) {
			return !this.projectName?.trim().length;
		}
		if (this.currentStep === 3) {
			return !this.project || this.project.service_requests.length === 0;
		}
		if (this.currentStep === 4) {
			return !this.checkIfAllServiceRequestsReady() || this.processingProject;
		}
		return false;
	}

	deleteUpload(request: any): void {
		const serviceRequest = this.project.service_requests.find((sr: any) => sr.asset && sr.asset.id === request.asset.id);
		if (serviceRequest) {
			this.api.delete(`project/${this.project.guid}/serviceRequest/${serviceRequest.id}`)
		}
		this.project.service_requests = this.project.service_requests.filter((sr: any) => sr.asset && sr.asset.id !== request.asset.id);
		for (let i = 0; i < this.project.service_requests.length; i++) {
			this.project.service_requests[i].image_name = `image_${i + 1}.jpg`;
			this.project.service_requests[i].name = `Image ${i + 1}`
		}
		this.getTotalForProject();
		this.getTotalDiscount();
		this.calculateCreditsApplied();

	}

	async cleanErrorMessages(): Promise<void> {
		setTimeout(() => {
			this.errorMessages = [];
		}, 5000)
	}

	async createProject(): Promise<void> {
		let options: any = {
			name: this.projectName,
			type: this.projectType,
		}
		if (this.continuation?.guid) {
			options['parent'] = this.continuation.guid;
		}
		try {
			const response: any = await this.api.post("project", options);
			this.project = response;
			this.applyAiCoupon();
		} catch (error) {
			// Handle the error
		}
	}

	hasAiDiscount(): boolean {
		return this.user.discount > 0;
	}

	applyAiCoupon(): void {
		if (!this.project.discount && this.hasAiDiscount()) {
			this.couponCode = 'VSAI';
			this.applyCouponApi();
		}
	}

	async getUpdatedProject(): Promise<void> {
		try {
			const response: any = await this.api.patch(`project/${this.project.guid}/calculate`);
			this.floorplan2Requests = await this.api.get(`project/${this.project.guid}/floorplans`);
			this.project = response;
			if (this.floorplan2Requests.length > 0) {
				this.project.service_requests = this.project.service_requests.concat(this.floorplan2Requests);
			}
			for (let serviceRequest of this.project.service_requests) {
				for (let service of serviceRequest.service_request_tasks) {
					if (typeof service.data === 'string') {
						try {
							service.data = JSON.parse(service.data);
						} catch (e) {
							console.error('JSON parsing failed for service:', service.name, 'Error:', e);
						}
					}
				}
			}
			this.applyAiCoupon();
			this.eta = this.getEta();
			this.getTotalForProject();
			this.getTotalDiscount();
			this.calculateCreditsApplied();

			// sort service request by service_request.sort
			this.project.service_requests.sort((a: any, b: any) => a.sort - b.sort);
		} catch (error) {
			// Handle the error
		}
	}

	deleteServiceRequest(serviceRequest: any): void {
		const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
			data: {
				message: "Are you sure you want to delete this photo?",
				confirm: {
					title: "Delete",
				},
				reject: {
					title: "Cancel",
				}
			}
		});
		confirmDialog.afterClosed().subscribe((result: any) => {
			if (result) {
				this.project.service_requests = this.project.service_requests.filter((sr: any) => sr.id !== serviceRequest.id);
				this.updateSortAndName(this.project.service_requests);
				this.api.delete(`project/${this.project.guid}/serviceRequest/${serviceRequest.id}`)
				if (serviceRequest.is_floor_plan) {
					if (this.project.service_requests.length === 0 || !this.project.service_requests.find((sr: any) => sr.is_floor_plan)) {
						this.fpBump = false;
					}
				}
				this.countServices();
				this.getTotalForProject();
				this.getTotalDiscount();
				this.calculateCreditsApplied();

				this.eta = this.getEta();
			}
		});
	}

	openServicesModal(serviceRequest: any, noAnimation?: boolean) {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.panelClass = 'service-modal';
		dialogConfig.data = {
			serviceRequest,
			projectGuid: this.project.guid,
			getEta: this.getEta.bind(this),
			project: this.project,
			projectType: this.projectType,
			tasks: this.tasks,
			roomTypes: this.roomTypes,
			furnitureStyles: this.furnitureStyles,
		};
		dialogConfig.maxWidth = '100vw';

		if (noAnimation) {
			dialogConfig.disableClose = true;
			dialogConfig.autoFocus = false;
			dialogConfig.hasBackdrop = false;
			dialogConfig.panelClass = 'no-animation';
		}

		const serviceModal = this.dialog.open(ServicesModalComponent, dialogConfig);

		serviceModal.componentRef?.instance.reopen.subscribe((serviceRequest: any) => {
			serviceModal.close();
			this.openServicesModal(serviceRequest);
		});

		serviceModal.afterClosed().subscribe((result: any) => {
			if (result?.goToNextImage) {
				const nextServiceRequest = this.project.service_requests.find((sr: any) => !this.checkIfServiceRequestReady(sr));
				if (nextServiceRequest) {
					this.openServicesModal(nextServiceRequest, true);
				}
			}
			if (serviceRequest.is_floor_plan) {
				this.countServices();
				if (this.serviceCounts["3D Floor Plan"] === 0) {
					if (this.fpBump) {
						this.project.floor_plan_token = null;
					}
				}
			}
			this.checkFpBump();
			this.getTotalForProject();
			this.getTotalDiscount();
			this.calculateCreditsApplied();

			this.eta = this.getEta();
		});
	}

	getThumbnail(serviceRequest: any): string {
		let url = '';
		if (serviceRequest.final_image) {
			url = environment.cdn + serviceRequest.final_image.image_name;
		} else if (serviceRequest.request_image) {
			url = environment.cdn + serviceRequest.request_image.image_name;
		} else if (serviceRequest.asset) {
			url = serviceRequest.asset.url;
		} else if (serviceRequest.final_asset) {
			url = serviceRequest.final_asset.url;
		}
		return environment.server + "/asset/thumbnail/" + url.split("/").slice(-1)[0];
	}

	onImageLoad(serviceRequest: any): void {
		this.loadedImages.add(serviceRequest);
	}

	getTotalForRequest(serviceRequest: any): number {
		let requestsWithVs = this.project.service_requests.filter((sr: any) => sr.service_request_tasks.find((task: any) => task.name === "Virtual Staging"));
		let requestTotal = serviceRequest.service_request_tasks.reduce((total: any, task: any) => {
			if (task.name === "Item Removal" && task.data?.imageData) {
				return total + task.data.imageData.length * 0.5;
			} else if (task.name === "Virtual Staging") {
				if (requestsWithVs.length <= 12) {
					return total + task.price;
				} else if (requestsWithVs.length > 12) {
					if (requestsWithVs.indexOf(serviceRequest) <= 11) {
						return total + task.price;
					} else if (requestsWithVs.indexOf(serviceRequest) <= 14) {
						return total;
					} else {
						return total + 19;
					}
				}
			}
			return total + task.price;
		}, 0);
		serviceRequest.total = requestTotal;
		return requestTotal;
	}

	getTotalForService(service: any): number {
		if (service.name === "Item Removal") {
			return service.data?.imageData?.length * 0.5;
		} else {
			return service.price;
		}
	}


	getTotalForProject(): number {
		let total = this.project.service_requests.reduce((total: any, serviceRequest: any) => total + this.getTotalForRequest(serviceRequest), 0);
		this.project.total = total;
		if (this.fpBump) {
			this.project.total += this.fpBumpPrice;
		}
		return total;
	}

	calculateCreditsApplied(): void {
		let vsCreditsApplied = 0;
		if (this.serviceCounts && this.serviceCounts["Virtual Staging"]) {
			let freeVs = this.seesBulkDiscount() ? Math.max(0, Math.min(this.serviceCounts["Virtual Staging"] - 12 || 0, 3)) : 0;
			vsCreditsApplied = Math.min(this.serviceCounts["Virtual Staging"] - freeVs || 0, this.wallet["vs credits"] || 0);
		}
		let walletFundsApplied = Math.min(this.project.total - ((vsCreditsApplied * 24) || 0) - this.totalDiscount, this.wallet.credits || 0);

		this.vsCreditsApplied = vsCreditsApplied > 0 ? vsCreditsApplied : 0;
		this.walletFundsApplied = walletFundsApplied > 0 ? walletFundsApplied : 0;
	}

	expandCollapse(requestId: string) {
		if (this.expandedRequest === requestId) {
			this.expandedRequest = "";
		} else {
			this.expandedRequest = requestId;
		}
	}

	getTasks(serviceRequest: any) {
		return serviceRequest.service_request_tasks.filter((task: any) => !task.main_task);
	}

	checkIfServiceRequestReady(serviceRequest: any) {
		return serviceRequest.service_request_tasks.filter((task: any) => !task.main_task).length > 0;
	}

	checkIfAllServiceRequestsReady() {
		for (let serviceRequest of this.project.service_requests) {
			if (!this.checkIfServiceRequestReady(serviceRequest)) {
				this.project.ready = false;
				return false;
			}
		}
		this.project.ready = true;
		return true;
	}

	checkIfAnyServiceRequestsReady() {
		for (let serviceRequest of this.project.service_requests) {
			if (this.checkIfServiceRequestReady(serviceRequest)) {
				return true;
			}
		} return false;
	}

	getEta(): string {
		if (!this.checkIfAnyServiceRequestsReady()) {
			return ''
		}
		this.countServices();
		this.getMaxEta();
		let vsRushEta = this.calculateVsRushEta();
		if (this.alwaysSameEta[0]?.active) {
			if (this.maxEta !== 48) {
				return this.alwaysSameEta[0].value;
			} else {
				return '48';
			}
		} else if (this.project.is_weekend) {
			return '24';
		} else if (this.rushSettings[0].active && this.isRush() && this.isOnlyVs()) {
			if (vsRushEta === 23) {
				return `8 to 24`;
			} else if (vsRushEta === 8) {
				return `4 to 8`;
			} else {
				return vsRushEta.toString();
			}
		} else {
			if (this.maxEta === 23) {
				return `8 to 24`;
			} else if (this.maxEta === 48) {
				return `Awaiting Review`
			} else {
				return this.maxEta.toString();
			}
		}
	}

	isOnlyVs(): boolean {
		let onlyVs = true;
		for (const [service, count] of Object.entries(this.serviceCounts) as [string, number][]) {
			if (service !== "Virtual Staging") {
				onlyVs = false;
			}
		}
		return onlyVs;
	}

	isRush(): boolean {
		let hours = this.rushSettings[0].value.split("|");
		let start = parseInt(hours[0], 10);
		let end = parseInt(hours[1], 10);

		const localTimePst = new Date().toLocaleString("en-US", {
			timeZone: "America/Los_Angeles",
		});
		const localTimePstDate = new Date(localTimePst);

		if (localTimePstDate.getHours() >= start && localTimePstDate.getHours() < end) {
			return true;
		} else {
			return false;
		}
	}

	calculateVsRushEta(): number {
		let vsCount = this.serviceCounts["Virtual Staging"];
		let rushSetting = this.etaSettings?.find((s: any) => s.name === "rush");

		if (vsCount > rushSetting?.number_for_twenty_four) {
			return 48;
		} else if (vsCount > rushSetting?.number_for_twenty) {
			return 24;
		} else if (vsCount > rushSetting?.number_for_eight) {
			return 23;
		} else {
			return 8;
		}
	}


	countServices() {
		let serviceCounts = this.project.service_requests.reduce((total: any, serviceRequest: any) => {
			for (let task of serviceRequest.service_request_tasks) {
				if (!task.main_task) {
					if (total[task.name]) {
						total[task.name] += 1;
					} else {
						total[task.name] = 1;
					}
					if (task.reference_assets?.length > 0) {
						if (total["ref"]) {
							total["ref"] += task.reference_assets.length;
						} else {
							total["ref"] = task.reference_assets.length;
						}
					}
				}
			}
			return total;
		}, {});
		this.serviceCounts = serviceCounts;
	}


	getMaxEta(): void {
		this.maxEta = 0;
		for (const [service, count] of Object.entries(this.serviceCounts) as [string, number][]) {
			let initials: string = "";
			if (service !== "Twilight" && service !== "ref" && service !== "3D Floor Plan") {
				initials = service.split(" ").map((word: string) => word[0]).join("").toLowerCase();
			} else if (service === "Twilight") {
				initials = "tw";
			} else if (service === "ref") {
				initials = "ref";
			} else if (service === "3D Floor Plan") {
				initials = "fp2";
			}
			let setting;
			if (this.etaSettings) {
				setting = this.etaSettings?.find((s: any) => s.name == initials);
			}
			if (setting) {
				if (this.maxEta === 0 || this.maxEta === 8) {
					if (count > setting?.number_for_twenty_four) {
						this.maxEta = 48;
						break;
					} else if (count > setting?.number_for_twenty) {
						this.maxEta = 24;
					} else if (count > setting?.number_for_eight) {
						this.maxEta = 23;
					} else {
						this.maxEta = 8;
					}
				} else if (this.maxEta === 24) {
					if (count > setting?.number_for_twenty_four) {
						this.maxEta = 48;
						break;
					}
				} else if (this.maxEta === 23) {
					if (count > setting?.number_for_twenty_four) {
						this.maxEta = 48;
						break;
					} else if (count > setting?.number_for_twenty) {
						this.maxEta = 24;

					}
				}
			}
		}
	}

	async payProject() {
		if (!this.checkIfAllServiceRequestsReady()) {
			return;
		}
		if (this.user.hsoa) {
			if (!this.user.real_estate_group) {
				let success = await this.openCompanyModal();
				if (!success) {
					this.processingProject = false;
					return;
				}
			}
		}
		this.processingProject = true;
		const response = await this.api.patch(
			`project/${this.project.guid}/calculate`
		);
		if (response.paid) {
			this.router.navigate([`/projects/${this.project.guid}/delivery`]);
			return;
		}
		this.project = response;
		this.reorderServiceRequests();
		this.getTotalForProject();
		this.calculateCreditsApplied();
		let paymentMethods = {
			wallet: 0,
			card: 0,
			credits: 0,
			currency: 0,
		};
		const walletResponse = await this.api.get("wallet/balance");
		let balance: number = walletResponse.credits;
		let balanceVS: number = walletResponse["vs credits"];
		let freeVs = this.seesBulkDiscount() ? Math.max(0, Math.min(this.serviceCounts["Virtual Staging"] - 12 || 0, 3)) : 0;
		paymentMethods.credits = Math.min(this.serviceCounts["Virtual Staging"] - freeVs || 0, balanceVS || 0);
		if (paymentMethods.credits > 0) {
			this.project.price -= paymentMethods.credits * 24;
		}

		paymentMethods.card = balance < this.project.price
			? parseInt((this.project.price - balance).toFixed(2))
			: 0;
		paymentMethods.wallet = balance < this.project.price ? balance : this.project.price.toFixed(2);
		paymentMethods.currency = paymentMethods.card * (this.user.conversion || 1);

		this.project.payment_methods = paymentMethods;

		balance = balance < 0 ? 0 : balance;

		if (balance < this.project.price) {
			const sessionId = await this.api.postDirect(
				`project/${this.project.guid}/paymentSession`,
				{
					client: environment.app_url,
					estimationProposal: this.eta !== 'Awaiting Review' ? this.eta : '48',
					cancel: `${environment.app_url}projects/${this.project.guid}/summary`,
					credits: this.project.payment_methods.credits,
					currency: this.user.currency,
					...(this.user.hsoa ? { hsoa: '1' } : {})
				}
			);
			if (sessionId?.paid) {
				this.router.navigate([`/projects/${this.project.guid}/delivery`]);
				return;
			}

			if (!sessionId) {
				this.processingProject = false;
				return;
			}
			localStorage.setItem("redirect_to", window.location.href);
			localStorage.setItem(
				"credits",
				this.project.payment_methods.credits
			);
			localStorage.setItem("card_payment", "1");
			localStorage.setItem(
				"wallet_credits",
				this.project.payment_methods.wallet
			);
			try {
				this.stripeAPI.redirectToCheckout({
					sessionId: sessionId,
				});
			} catch (e) {
				this.processingProject = false;
			}
		} else {
			await this.api.post(`project/${this.project.guid}/reserveFunds`, {
				wallet: true,
				estimationProposal: this.eta !== 'Awaiting Review' ? this.eta : '48',
				credits: this.project.payment_methods.credits,
				amount: this.project.payment_methods.wallet,
				inQueue: null,
			});

			if (this.project.payment_methods.credits > 0) {
				this.pixel.trackEvent("creditsUsed", {
					value: this.project.payment_methods.credits,
					currency: "USD",
				});
				this.bing.trackEvent("purchase", {
					event_label: "credits",
					revenue_value: this.project.payment_methods.credits,
					currency: "USD",
				});
			}

			localStorage.removeItem(`signatureDisclaimer-${this.project.guid}`);
			localStorage.removeItem(`style-${this.project.guid}`);
			this.processingProject = false;
			this.router.navigate([`/projects/${this.project.guid}/delivery`]);
			return;
		}
	}

	reorderServiceRequests() {
		this.project.service_requests.sort((a: any, b: any) => {
			return a.sort - b.sort;
		});
	}

	getTooltip() {
		if (this.checkIfNextStepDisabled() && (this.currentStep >= 3)) {
			return "Please add services for every image you uploaded to continue. Alternatively, you can remove the images you don't need edited. Once you do this you will be able to start your project.";
		} else if (this.checkIfNextStepDisabled() && (this.currentStep === 2)) {
			return "Please name your project to continue.";
		} else {
			return "";
		}
	}

	onDragOver(event: any) {
		event.preventDefault();
		event.stopPropagation();
		this.dragging = true;
	}

	onDragLeave(event: any) {
		event.preventDefault();
		event.stopPropagation();
		this.dragging = false;
	}

	onDrop(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();

		if (event.dataTransfer?.files) {
			const mockEvent = { target: { files: event.dataTransfer.files } };
			this.onFileSelected(mockEvent as any);
		}
		this.dragging = false;
	}

	logOut() {
		localStorage.clear();
		this.router.navigate(["/login"]);
	}

	async seesSpecialOffer(): Promise<void> {
		if (this.specialStillValid()) {
			let projects = await this.api.get("projects/v2");
			if (projects.length > 1) {
				let allDraft = projects.every((project: any) => project.status === "Draft");
				this.specialOfferValid = allDraft;
			} else if (projects.length === 1) {
				if (projects[0].status !== "Draft") {
					this.specialOfferValid = false;
				} else {
					this.specialOfferValid = true;
				}
			} else {
				this.specialOfferValid = false;
			}
		} else {
			this.specialOfferValid = false;
		}
	}

	specialActivated(): boolean {
		return this.serviceCounts && this.serviceCounts["Virtual Staging"] >= 12;
	}

	show12MonthsOfferDialog() {
		let options: any = {
			panelClass: 'twelve-months-offer-dialog',
			maxWidth: this.breakpointService.currentBreakpointClass === 'is-phone-portrait' ? '100vw' : '65rem',
		}
		this.dialog.open(this.twelveMonthsOfferDialog, options);
	}

	specialStillValid(): boolean {
		let regDate = moment.tz(this.user.created, 'America/Los_Angeles');

		let expiryDate = regDate.clone().add(2, 'days').startOf('day');

		let now = moment().tz('America/Los_Angeles');

		return now.isBefore(expiryDate);
	}

	getTimeUntilExpiry(): any {
		let regDate = moment.tz(this.user.created, 'America/Los_Angeles');

		let expiryDate = regDate.clone().add(2, 'days').startOf('day');

		let now = moment().tz('America/Los_Angeles');

		if (now.isAfter(expiryDate)) {
			return { hours: 0, minutes: 0 };
		} else {
			let duration = moment.duration(expiryDate.diff(now));
			let hours = Math.floor(duration.asHours());
			let minutes = duration.minutes();
			return {
				hours: hours,
				minutes: minutes,
			};
		}
	}


	onUpdate() {
		let services = this.project.service_requests.map((service: any, index: any) => ({
			id: service.id,
			index: index + 1
		}));
		this.updateSortAndName(services);
		this.api
			.patch(`project/${this.project.guid}/serviceRequests/reorder`, {
				services
			})
	}

	updateSortAndName(services: any) {
		for (let i = 0; i < services.length; i++) {
			this.project.service_requests.find((service: any) => {
				if (service.id === services[i].id) {
					service.sort = i + 1;
					service.name = `Image ${i + 1}`;
				}
			});
		}
	}

	orderItems(): any {
		return this.project.service_requests;
	}

	floorplansTotal(): number {
		return this.floorplan2Requests.reduce((total: any, serviceRequest: any) => total + this.getTotalForRequest(serviceRequest), 0);
	}

	async addFpBump(): Promise<void> {
		if (this.fpBump) {
			this.fpBump = false;
			await this.api.post(`project/${this.project.guid}/3d-floor-plan`, {
				is_order_bump: '0',
			})
			this.project.total -= this.fpBumpPrice;
			return;
		} else {
			this.fpBump = true;
			await this.api.post(`project/${this.project.guid}/3d-floor-plan`, {
				is_order_bump: '1',
			})
			this.project.total += this.fpBumpPrice;
			return;
		}
	}

	getFloorplan2Name(floorplanRequest: any): string {
		return "Floor plan " + (indexOf(this.floorplan2Requests, floorplanRequest) + 1);
	}

	checkFpBump(): void {
		if (this.project.floor_plan_token) {
			this.fpBump = true;
		} else {
			this.fpBump = false;
		}
	}

	openPhoneModal() {
		this.dialog.open(UpdatePhoneComponent, {
			panelClass: 'phone-modal',
			maxWidth: '100vw',
		});
	}

	openCompanyModal(): Promise<boolean> {
		let success = firstValueFrom(this.dialog.open(UpdateCompanyComponent, {
			panelClass: 'company-modal',
			maxWidth: '100vw',
		}).afterClosed());
		return success;
	}

	getExampleUrl(): string {
		return `${environment.app_url}p/lp/YmZkMjdiN2RmMzMzYWE4`;
	}

	showFpBump() {
		return this.serviceCounts["3D Floor Plan"] > 0 && this.serviceCounts["Virtual Staging"] >= 3;
	}

	displayTotal(): number {
		return Math.max(this.project.total - this.couponDiscount - this.vsCreditsApplied * 24 - this.walletFundsApplied, 0);
	}
}
