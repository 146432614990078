import { Component, Input, OnInit, ComponentRef, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ApiService } from 'src/app/services/api.service';
import { VsInfoComponent } from '../services-modal/service-info/vs-info/vs-info.component';
import { FcInfoComponent } from '../services-modal/service-info/fc-info/fc-info.component';
import { IrInfoComponent } from '../services-modal/service-info/ir-info/ir-info.component';
import { IeInfoComponent } from '../services-modal/service-info/ie-info/ie-info.component';
import { TwilightInfoComponent } from '../services-modal/service-info/twilight-info/twilight-info.component';
import { WcInfoComponent } from '../services-modal/service-info/wc-info/wc-info.component';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { environment } from 'src/app/environments/environment';
import {
    MatBottomSheet
} from '@angular/material/bottom-sheet';
import { ServiceInfoComponent } from './service-info/service-info.component';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RedfinCouponComponent } from '../redfin-coupon/redfin-coupon.component';
import { VrInfoComponent } from '../services-modal/service-info/vr-info/vr-info.component';
import { FpInfoComponent } from '../services-modal/service-info/fp-info/fp-info.component';
import moment from 'moment-timezone';
import { indexOf } from 'lodash';
import { UpdatePhoneComponent } from 'src/app/components/update-phone/update-phone.component';
import { Fp2InfoComponent } from '../services-modal/service-info/fp2-info/fp2-info.component';


@Component({
    selector: 'app-phone-summary',
    templateUrl: './phone-summary.component.html',
    styleUrls: ['./phone-summary.component.scss'],
    animations: [
        trigger('slideAnimation', [
            state('prev', style({
                transform: 'translate(-160%, -50%)',
                opacity: 0.2,
                pointerEvents: 'none'
            })),
            state('active', style({
                transform: 'translate(-50%, -50%)',
                opacity: 1
            })),
            state('next', style({
                transform: 'translate(60%, -50%)',
                opacity: 0.2,
                pointerEvents: 'none'
            })),
            transition('active => prev', animate('600ms ease')),
            transition('active => next', animate('600ms ease')),
            transition('prev => active', animate('600ms ease')),
            transition('next => active', animate('600ms ease'))
        ])
    ]

})
export class PhoneSummaryComponent implements OnInit {
    @Input() project: any;
    public currentRequests: any;
    public startIndex: number = 0;
    public totalImages: number = 0;
    @Input() serviceTypes: any;
    public selectedServiceType: any = {
        id: null,
    };
    public missingInfo: boolean = false;
    public annotationsOverlayVisible: boolean = false;
    public currentAnnotations: any = [];
    public irData: any = [];
    public serviceRequest: any;
    private componentRef: ComponentRef<any> | undefined;
    @ViewChild('serviceInfoAnchor', { read: ViewContainerRef }) serviceInfoAnchor: ViewContainerRef | undefined;
    @Input() furnitureStyles: any;
    @Input() roomTypes: any;
    public addingService: boolean = false;
    public savingService: boolean = false;
    public totalProgress: number = 0;
    public uploading: boolean = false;
    public uploadingFloorplans: boolean = false;
    public uploadProgress: any = {};
    public completedFiles: number = 0;
    @Input() ppCheckout: boolean = false;
    @Input() serviceCounts: any;
    @Input() eta: any;
    @Input() couponEnabled: boolean = false;
    @Input() couponCode: string = '';
    @Input() limitValue: number = 13;
    @Input() floorplan2Requests: any;
    public vrAdded: boolean = false;
    public fp2Added: boolean = false;
    public errorMessages: string[] = [];
    public processingUploads: boolean = false;
    public showUploadOverlay: boolean = false;
    public totalDiscount: number = 0;
    public couponDiscount: number = 0;
    @Input() wallet: any;
    public vsCreditsApplied: number = 0;
    public walletFundsApplied: number = 0;
    public projectType: string | null = "";
    @ViewChild('12MonthsOfferDialog') twelveMonthsOfferDialog!: TemplateRef<any>;
    public specialOfferValid: boolean = true;
    public user: any;
    public floorplanBumpTask: any;
    public fpBump: boolean = false;
    public fpBumpPrice: number = 30;

    constructor(
        private api: ApiService,
        public breakpointService: BreakpointService,
        private bottomSheet: MatBottomSheet,
        public dialog: MatDialog,
    ) {
        this.user = localStorage.getItem("user");
        this.user = JSON.parse(this.user);
    }

    ngOnInit(): void {
        this.serviceRequest = this.project.service_requests[0];
        this.totalImages = this.project.service_requests.length;
        this.projectType = this.project.type;
        this.displayInitialRequests();
        this.floorplanBumpTask = this.serviceTypes.find((task: any) => task.name === '3D Floor Plan');
        if (this.isFp()) {
            this.serviceTypes = this.serviceTypes.filter((task: any) => task.name === 'Floor Plan');
        } else {
            this.serviceTypes = this.serviceTypes.filter((task: any) => task.name !== 'Floor Plan');
        }
        if ('scrollRestoration' in history) {
            history.scrollRestoration = 'manual';
        }
        if (this.currentRequests?.length === 3) {
            this.loadRequest();
        }
        this.getTotalForProject();
        this.getTotalDiscount();
        this.calculateCreditsApplied();

        this.seesSpecialOffer();
        this.checkFpBump();
    }

    isFp(): boolean {
        return this.projectType === 'floorplan';
    }

    getFpTask(): any {
        if (this.serviceTypes) {
            return this.serviceTypes.find((task: any) => task.name === 'Floor Plan');
        } else {
            return null;
        }
    }


    loadRequest() {
        this.checkIf360AndAddFlags();
        this.serviceTypes.sort((a: any, b: any) => a.sort - b.sort);
        this.serviceTypes = this.serviceTypes.map((service: any) => {
            if (this.serviceRequest.service_request_tasks.find((task: any) => task.settings_task?.id === service.id)
            ) {
                service.added = true;
            } else {
                service.added = false;
            }
            return service;
        });
        if (this.serviceRequest.service_request_tasks.length > 0) {
            let irTask = this.serviceRequest.service_request_tasks.find((task: any) => task.name === "Item Removal");
            if (irTask) {
                if (typeof irTask.data === 'string') {
                    this.irData = JSON.parse(irTask.data).imageData;
                } else {
                    this.irData = irTask.data.imageData;
                }
            }
            let vrTask = this.serviceRequest.service_request_tasks.find((task: any) => task.name === "Virtual Renovation");
            if (vrTask) {
                this.vrAdded = true;
            } else {
                this.vrAdded = false;
            }
            let fp2Task = this.serviceRequest.service_request_tasks.find((task: any) => task.name === "3D Floor Plan");
            if (fp2Task) {
                this.fp2Added = true;
            } else {
                this.fp2Added = false;
            }
        }
    }

    displayInitialRequests() {
        if (this.totalImages === 0) {
            return;
        }
        let firstThree: any;
        if (this.totalImages > 3) {
            this.startIndex = -1;
            this.currentRequests = this.getThree(this.totalImages - 1, 0, 1);
        } else if (this.totalImages === 3) {
            firstThree = this.getThree(this.totalImages - 1, 0, 1);
            for (let item of firstThree) {
                item.trackId = item.id;
            }
            this.currentRequests = [...firstThree];
        } else if (this.totalImages === 2) {
            this.startIndex = 1;
            firstThree = this.getThree(1, 0, 1);
            for (let i = 0; i < firstThree.length; i++) {
                firstThree[i] = { ...firstThree[i], trackId: Math.floor(Math.random() * 1000000) + i };
            }
            this.currentRequests = [...firstThree];
        } else if (this.totalImages === 1) {
            firstThree = this.getThree(0, 0, 0);
            for (let item of firstThree) {
                item.trackId = item.id;
            }
            this.currentRequests = [...firstThree];
        }
    }


    slide(direction: number) {
        if (this.totalImages <= 1) {
            return;
        }

        if (direction === 1) {
            this.startIndex = (this.startIndex + 1) % this.totalImages;
        } else {
            this.startIndex = (this.startIndex - 1 + this.totalImages) % this.totalImages;
        }

        if (this.totalImages === 2) {
            if (direction === 1) {
                this.currentRequests.shift();
                let nextRequest = { ...this.project.service_requests[this.startIndex] };
                nextRequest.trackId = Math.floor(Math.random() * 1000000);
                this.currentRequests.push(nextRequest);
            } else {
                this.currentRequests.pop();
                let prevRequest = { ...this.project.service_requests[this.startIndex] };
                prevRequest.trackId = Math.floor(Math.random() * 1000000);
                this.currentRequests.unshift(prevRequest);
            }
        } else if (this.totalImages === 3) {
            if (direction === 1) {
                this.currentRequests.shift();
                let nextIndex = (this.startIndex + 1) % this.totalImages;
                let nextRequest = { ...this.project.service_requests[nextIndex] };
                nextRequest.trackId = Math.floor(Math.random() * 1000000);
                this.currentRequests.push(nextRequest);
            } else {
                this.currentRequests.pop();
                let prevIndex = (this.startIndex + this.totalImages - 1) % this.totalImages;
                let prevRequest = { ...this.project.service_requests[prevIndex] };
                prevRequest.trackId = Math.floor(Math.random() * 1000000);
                this.currentRequests.unshift(prevRequest);
            }
        } else if (this.totalImages > 3) {
            this.currentRequests = this.getThree(
                this.startIndex,
                (this.startIndex + 1) % this.totalImages,
                (this.startIndex + 2) % this.totalImages
            );
        }
        this.serviceRequest = this.currentRequests[1];
        this.loadRequest();
    }

    getThree(one: number, two: number, three: number) {
        const newArray = [];
        newArray.push(this.project.service_requests[one % this.totalImages]);
        newArray.push(this.project.service_requests[two % this.totalImages]);
        newArray.push(this.project.service_requests[three % this.totalImages]);
        return newArray;
    }

    getState(index: number): string {
        if (index === 0) return 'prev';
        if (index === 1) return 'active';
        if (index === 2) return 'next';
        return 'inactive';
    }

    trackByFn(index: number, item: any): number {
        return item.trackId || item.id;
    }

    selectService(service: any, event: any) {
        this.selectedServiceType = service;

        if (service.added) {
            this.currentAnnotations = this.serviceRequest.service_request_tasks.find((task: any) => task.settings_task.id === service.id).data.imageData;
            if (service.name === "Item Removal") {
                this.irData = this.serviceRequest.service_request_tasks.find((task: any) => task.settings_task.id === service.id).data.imageData;
            }
            if (this.currentAnnotations?.length > 0) {
                this.annotationsOverlayVisible = false;
            }
        } else {
            this.currentAnnotations = [];
            this.irData = [];
            this.annotationsOverlayVisible = true;
        }

        if (this.componentRef) {
            this.componentRef.destroy();
        }

        let component: any;

        switch (service.name) {
            case "360 Virtual Staging":
                component = VsInfoComponent;
                break;
            case "Virtual Staging":
                component = VsInfoComponent;
                break;
            case "Floor Change":
                component = FcInfoComponent;
                break;
            case "Item Removal":
                component = IrInfoComponent;
                break;
            case "Image Enhancement":
                component = IeInfoComponent;
                break;
            case "Twilight":
                component = TwilightInfoComponent;
                break;
            case "Wall Change":
                component = WcInfoComponent;
                break;
            case "Virtual Renovation":
                component = VrInfoComponent;
                break;
            case "Floor Plan":
                component = FpInfoComponent;
                break;
            case "3D Floor Plan":
                component = Fp2InfoComponent;
                break;
            default:
                break;
        }


        let sheetRef = this.bottomSheet.open(ServiceInfoComponent, {
            data: {
                currentComponent: component,
                selectedServiceType: this.selectedServiceType,
                serviceRequest: this.serviceRequest,
                task: this.selectedServiceType,
                projectGuid: this.project.guid,
                annotations: this.currentAnnotations,
                roomTypes: this.roomTypes,
                furnitureStyles: this.furnitureStyles,
                project: this.project,
                serviceTypes: this.serviceTypes,
            },
            panelClass: 'service-info-bottom-sheet',
            disableClose: true,
        });
        sheetRef.afterDismissed().subscribe(() => {
            this.selectedServiceType = {
                id: null,
            }
        });
        sheetRef.instance.updatedRequest.subscribe((serviceRequest: any) => {
            if (serviceRequest.is_floor_plan) {
                this.countServices();
                if (this.serviceCounts["3D Floor Plan"] === 0) {
                    if (this.fpBump) {
                        this.project.floor_plan_token = null;
                    }
                }
            }
            this.checkFpBump();
            this.getTotalForProject();
            this.getTotalDiscount();
            this.calculateCreditsApplied();

            this.loadRequest();
            this.currentRequests = this.currentRequests.map((sr: any) => {
                if (sr.id === serviceRequest.id) {
                    return serviceRequest;
                }
                return sr;
            });
        });
        sheetRef.instance.vrAdded.subscribe(() => {
            this.vrAdded = true;
        });
        sheetRef.instance.vrRemoved.subscribe(() => {
            this.vrAdded = false;
        });
        sheetRef.instance.fp2Added.subscribe(() => {
            this.fp2Added = true;
        });
        sheetRef.instance.fp2Removed.subscribe(() => {
            this.fp2Added = false;
        });
    }

    countServices() {
        let serviceCounts = this.project.service_requests.reduce((total: any, serviceRequest: any) => {
            for (let task of serviceRequest.service_request_tasks) {
                if (!task.main_task) {
                    if (total[task.name]) {
                        total[task.name] += 1;
                    } else {
                        total[task.name] = 1;
                    }
                    if (task.reference_assets?.length > 0) {
                        if (total["ref"]) {
                            total["ref"] += task.reference_assets.length;
                        } else {
                            total["ref"] = task.reference_assets.length;
                        }
                    }
                }
            }
            return total;
        }, {});
        this.serviceCounts = serviceCounts;
    }

    checkIf360AndAddFlags() {
        let servicesAdded = [];
        for (let service of this.serviceTypes) {
            if (this.serviceRequest?.service_request_tasks?.length === 0) {
                service.added = false;
            } else if (this.serviceRequest?.service_request_tasks?.find((task: any) => task.settings_task.id === service.id)) {
                service.added = true;
                servicesAdded.push(service);
            }
            service.disabled = false;
            if (service.name === "360 Virtual Staging") {
                if (!this.serviceRequest.asset.is360) {
                    service.disabled = true;
                    service.sort = 7
                    service.selected = false;
                } else {
                    service.disabled = false;
                    service.sort = 1
                    service.selected = true;
                }
            }
            if (service.name === "Virtual Staging") {
                if (this.serviceRequest?.asset.is360) {
                    service.disabled = true;
                    service.sort = 7
                } else {
                    service.disabled = false;
                    service.sort = 1
                }
            }
            service.icon = this.toCamelCase(service.name);
        }
        if (servicesAdded.length > 0) {
            if (servicesAdded.find((service: any) => service.name === "3D Floor Plan")) {
                this.fp2Added = true;
            } else {
                this.fp2Added = false;
            }

            if (servicesAdded.find((service: any) => service.name === "Virtual Renovation")) {
                this.vrAdded = true;
            } else {
                this.vrAdded = false;
            }
        } else {
            this.vrAdded = false;
            this.fp2Added = false;
        }
    }

    toCamelCase(str: string) {
        let icon: string;
        switch (str) {
            case "Floor Change":
                icon = 'fcMobile';
                break;
            case "Item Removal":
                icon = 'irMobile';
                break;
            case "Image Enhancement":
                icon = 'ieMobile';
                break;
            case "Twilight":
                icon = 'twMobile';
                break;
            case "Wall Change":
                icon = 'wcMobile';
                break;
            case "Virtual Renovation":
                icon = 'vrMobile';
                break;
            case "3D Floor Plan":
                icon = 'fpMobile';
                break;
            default:
                icon = 'vsMobile';
                break;
        }
        return icon;
    }

    checkIfServiceAdded(serviceId: any) {
        return this.serviceTypes.find((service: any) => service.id === serviceId).added;
    }

    handleAnnotationsChange(annotations: any) {
        this.currentAnnotations = [...annotations];
        if (this.componentRef && this.componentRef.instance) {
            this.componentRef.instance.service.data.imageData = this.currentAnnotations;
            this.componentRef.instance.checkIfNeedsToSave();
        }
    }

    getThumbnailFromUrl() {
        let url = environment.cdn + this.serviceRequest.asset.url;
        return environment.server + "/asset/thumbnail/" + url.split("/").slice(-1)[0];
    }

    getInitialTask() {
        return this.serviceRequest.service_request_tasks.find((task: any) => task.name === this.selectedServiceType.name);
    }

    calculatePrice(service: any) {
        if (service.name === "Item Removal") {
            return this.calculateIrTotal();
        } else {
            return service.price;
        }
    }

    calculateIrTotal(): number {
        let total = 0;
        for (let task of this.serviceRequest.service_request_tasks) {
            if (task.name === "Item Removal") {
                let boxes = task.data.imageData.length;
                total += boxes * 0.5;
            }
        }
        return total;
    }

    getAddMoreStyle() {
        const progress = this.totalProgress || 0;
        return {
            'height': `${progress}%`
        };
    }

    isHeicFile(file: any): boolean {
        return file.name.toLowerCase().endsWith('.heic');
    }

    async onFileSelected(event: Event): Promise<void> {
        const input = event.target as HTMLInputElement;
        let totalFiles = input.files ? input.files.length : 0;

        let validFiles: any = [];

        if (input.files && totalFiles > 0) {

            for (let i = 0; i < totalFiles; i++) {
                const file = input.files[i];

                this.uploading = true;
                if (file.size > 125 * 1024 * 1024) {
                    this.errorMessages.push(`The file ${file.name} is too large. The maximum file size is 125MB.`);
                    this.cleanErrorMessages();
                    if (validFiles.length === 0) {
                        this.totalProgress = 0;
                        this.completedFiles = 0;
                        this.uploadProgress = {};

                        this.uploading = false;
                    }
                    continue;
                }

                if (!file.type.startsWith('image/') && !this.isHeicFile(file)) {
                    this.errorMessages.push(`The file ${file.name} is not an image.`);
                    this.cleanErrorMessages();
                    if (validFiles.length === 0) {
                        this.totalProgress = 0;
                        this.completedFiles = 0;
                        this.uploadProgress = {};

                        this.uploading = false;
                    }
                    continue;
                }

                if (!['image/x-png', 'image/gif', 'image/jpeg', 'image/png', 'image/jpg', 'image/webp', 'image/tiff', 'image/heic'].includes(file.type) && !this.isHeicFile(file)) {
                    this.errorMessages.push(`The file type of ${file.name} is unsupported .`);
                    this.cleanErrorMessages();
                    if (validFiles === 0) {
                        this.totalProgress = 0;
                        this.completedFiles = 0;
                        this.uploadProgress = {};

                        this.uploading = false;
                    }
                    continue;
                }

                validFiles.push(file);
            }
            for (let i = 0; i < validFiles.length; i++) {
                const formData = new FormData();
                let sanitizedFilename = this.sanitizeFilename(validFiles[i].name);
                formData.append('imageFile', validFiles[i], sanitizedFilename);
                formData.append('index', (i + 1).toString());
                try {
                    await this.uploadFile(formData, sanitizedFilename, validFiles.length);
                } catch (error) {
                    // Handle the error
                }
            }

            // Reset total progress if all files have been uploaded
            if (this.completedFiles === validFiles.length) {
                this.totalProgress = 0;
                this.completedFiles = 0;
                this.uploadProgress = {};
                await this.getUpdatedProject();
                this.totalImages = this.project.service_requests.length;
                if (this.totalImages === 1) {
                    this.loadRequest();
                }
                this.processingUploads = false;
                this.showUploadOverlay = false;

                this.uploading = false;
            }
        }
    }

    private async uploadFile(formData: FormData, fileName: string, validFiles: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.api.upload(`project/${this.project.guid}/serviceRequest`, formData).subscribe({
                next: (event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        let progress: number = 0;
                        if (event.total) {
                            progress = 100 / event.total * event.loaded;
                        }
                        this.uploadProgress[fileName] = progress;
                        let accumulatedProgress = 0;
                        for (let key in this.uploadProgress) {
                            accumulatedProgress += this.uploadProgress[key];
                        }
                        this.totalProgress = Math.round(accumulatedProgress / validFiles);

                    } else if (event instanceof HttpResponse) {
                        // File uploaded successfully
                        this.completedFiles++;
                        resolve();
                    }
                },
                error: (error) => {
                    // Handle upload error
                    this.errorMessages.push(`Failed to upload ${fileName}. Please try again.`);
                    this.cleanErrorMessages();
                    reject(error);
                },
                complete: () => {
                    // Optionally handle completion if needed
                }
            });
        });
    }

    sanitizeFilename(filename: string) {
        let extension = filename.split('.').pop();
        extension = extension ? '.' + extension : '';

        let nameWithoutExtension = filename.replace(extension, '');
        const sanitized = nameWithoutExtension
            .replace(/[^a-zA-Z0-9-_.\s]/g, '')
            .replace(/\s+/g, '_')
            .substring(0, 150 - extension.length) + extension;

        return sanitized.toLowerCase();
    }

    async cleanErrorMessages(): Promise<void> {
        setTimeout(() => {
            this.errorMessages = [];
        }, 5000)
    }

    async getUpdatedProject(): Promise<void> {
        try {
            const response: any = await this.api.patch(`project/${this.project.guid}/calculate`);
            Object.assign(this.project, response);
            this.floorplan2Requests = await this.api.get(`project/${this.project.guid}/floorplans`);
            this.totalImages = this.project.service_requests.length;
            for (let request of this.project.service_requests) {
                this.parseDataInServiceRequest(request);
            }
            this.applyAiCoupon();
            this.displayInitialRequests();
            this.serviceRequest = this.currentRequests[1];
            this.getTotalForProject();
            this.getTotalDiscount();
            this.calculateCreditsApplied();

        } catch (error) {
            // Handle the error
        }
    }

    hasAiDiscount(): boolean {
        return this.user.discount > 0;
    }

    applyAiCoupon(): void {
        if (!this.project.discount && this.hasAiDiscount()) {
            this.couponCode = 'VSAI';
            this.applyCouponApi();
        }
    }

    parseDataInServiceRequest(serviceRequest: any) {
        serviceRequest.service_request_tasks.map((task: any) => {
            if (task.data && typeof task.data === 'string') {
                task.data = JSON.parse(task.data);
            }
        });
    }

    checkIfServiceRequestReady(serviceRequest: any) {
        return serviceRequest.service_request_tasks.filter((task: any) => !task.main_task).length > 0;
    }

    getThumbnail(serviceRequest: any): string {
        let url = environment.cdn + serviceRequest.asset.url;
        return environment.server + "/asset/thumbnail/" + url.split("/").slice(-1)[0];
    }

    deleteServiceRequest(serviceRequest: any, isFloorplan: boolean = false): void {
        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                message: "Are you sure you want to delete this photo?",
                confirm: {
                    title: "Delete",
                },
                reject: {
                    title: "Cancel",
                }
            }
        });
        confirmDialog.afterClosed().subscribe((result: any) => {
            if (result) {
                this.project.service_requests = this.project.service_requests.filter((sr: any) => sr.id !== serviceRequest.id);
                for (let i = 0; i < this.project.service_requests.length; i++) {
                    this.project.service_requests[i].image_name = `image_${i + 1}.jpg`;
                    this.project.service_requests[i].name = `Image ${i + 1}`
                }
                this.api.delete(`project/${this.project.guid}/serviceRequest/${serviceRequest.id}`)
                if (serviceRequest.is_floor_plan) {
                    if (this.project.service_requests.length === 0 || !this.project.service_requests.find((sr: any) => sr.is_floor_plan)) {
                        this.fpBump = false;
                    }
                }
                this.totalImages = this.project.service_requests.length;
                this.countServices();
                this.displayInitialRequests();
                this.serviceRequest = this.currentRequests[1];
                this.loadRequest();
                this.getTotalForProject();
                this.getTotalDiscount();
                this.calculateCreditsApplied();
            }
        });
    }

    getTotalForRequest(serviceRequest: any): number {
        let requestsWithVs = this.project.service_requests.filter((sr: any) => sr.service_request_tasks.find((task: any) => task.name === "Virtual Staging"));
        let requestTotal = serviceRequest.service_request_tasks.reduce((total: any, task: any) => {
            if (task.name === "Item Removal" && task.data?.imageData) {
                return total + task.data.imageData.length * 0.5;
            } else if (task.name === "Virtual Staging") {
                if (requestsWithVs.length <= 12) {
                    return total + task.price;
                } else if (requestsWithVs.length > 12) {
                    if (requestsWithVs.indexOf(serviceRequest) <= 11) {
                        return total + task.price;
                    } else if (requestsWithVs.indexOf(serviceRequest) <= 14) {
                        return total;
                    } else {
                        return total + 19;
                    }
                }
            }
            return total + task.price;
        }, 0);
        serviceRequest.total = requestTotal;
        return requestTotal;
    }

    getTotalForProject(): number {
        let total = this.project.service_requests.reduce((total: any, serviceRequest: any) => total + this.getTotalForRequest(serviceRequest), 0);
        this.project.total = total;
        if (this.fpBump) {
            this.project.total += this.fpBumpPrice;
        }
        return total;
    }

    calculateCreditsApplied(): void {
        let vsCreditsApplied = 0;
        if (this.serviceCounts && this.serviceCounts["Virtual Staging"]) {
            let freeVs = this.seesBulkDiscount() ? Math.max(0, Math.min(this.serviceCounts["Virtual Staging"] - 12 || 0, 3)) : 0;
            vsCreditsApplied = Math.min(this.serviceCounts["Virtual Staging"] - freeVs || 0, this.wallet["vs credits"] || 0);
        }
        let walletFundsApplied = Math.min(this.project.total - ((vsCreditsApplied * 24) || 0), this.wallet.credits || 0);

        this.vsCreditsApplied = vsCreditsApplied > 0 ? vsCreditsApplied : 0;
        this.walletFundsApplied = walletFundsApplied > 0 ? walletFundsApplied : 0;
    }

    seesBulkDiscount(): boolean {
        return this.project && !this.project.discount;
    }

    handleCouponKeydown(event: KeyboardEvent) {
        if (!this.couponCode) {
            return;
        }
        if (event.key === 'Enter') {
            this.checkBeforeApplyingCoupon();
        }
    }

    async checkBeforeApplyingCoupon() {
        if (this.couponCode === 'SOTHEBYS100') {
            const confirmed = await this.sothebysConfirmation();

            if (!confirmed) {
                return;
            } else {
                this.applyCouponApi();
            }
        } else if (this.couponCode === 'REDFIN') {
            await this.applyCouponApi();
            let regions: any[] = [];

            for (let [key, value] of Object.entries(this.project.discount.markets)) {
                let region: any = {
                    name: key,
                    markets: value,
                }
                regions.push(region);
            }

            if (regions?.length > 0) {
                const result = await this.redfinExtraInfo(regions);
                if (!result) {
                    return;
                } else {
                    await this.saveAditionalInfos({ 'Listing ID': result.listingId, 'Business Area/Market': result.market });
                }
            }

        } else {
            this.applyCouponApi()
        }
    }

    async applyCouponApi(): Promise<void> {
        await this.api
            .patch(
                `project/${this.project.guid}/coupon/${this.couponCode}/apply`
            )
            .then(
                (project) => {
                    Object.assign(this.project, project);
                    this.getTotalForProject();
                    this.getTotalDiscount();
                    this.calculateCreditsApplied();

                }
            )
    }

    async redfinExtraInfo(regions: any): Promise<any> {
        let dialogRef = this.dialog.open(RedfinCouponComponent, {
            data: {
                regions,
            },
            disableClose: true,
            maxWidth: '100vw',
        });
        return new Promise<any>((resolve) => {
            dialogRef.afterClosed().subscribe((result: any) => {
                resolve(result);
            });
        });
    }

    async saveAditionalInfos(values: any) {
        this.api.put(`project/${this.project.guid}/additional`, {
            additionalFields: JSON.stringify(values),
        })
    }

    async sothebysConfirmation(): Promise<boolean> {
        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                icon: 'warning',
                message: "Please confirm eligibility",
                moreInfo: "This coupon code is exclusively available to authorized Sotheby's International Realty agents who are part of the East Side and Downtown offices. Please confirm your eligibility to use the coupon code before proceeding.",
                confirm: {
                    title: "Confirm",
                },
                reject: {
                    title: "Cancel",
                }
            }
        });

        return new Promise<boolean>((resolve) => {
            dialogRef.afterClosed().subscribe((result: any) => {
                resolve(!!result);
            });
        });
    }

    getTotalDiscount(): void {
        let percentDiscount = this.project.discount?.discount;
        let totalDiscount = 0;
        let couponDiscount = 0;
        if (percentDiscount) {
            totalDiscount += this.project.total / 100 * percentDiscount;
            couponDiscount += this.project.total / 100 * percentDiscount;
        }
        if (this.seesBulkDiscount()) {
            if (this.serviceCounts && this.serviceCounts["Virtual Staging"]) {
                if (this.serviceCounts["Virtual Staging"] > this.limitValue) {
                    totalDiscount += Math.min(this.serviceCounts["Virtual Staging"] - 12, 3) * 24;
                }
                if (this.serviceCounts["Virtual Staging"] > this.limitValue + 3) {
                    totalDiscount += (this.serviceCounts["Virtual Staging"] - 15) * (24 - 19);
                }
            }
        }
        this.totalDiscount = totalDiscount;
        this.couponDiscount = couponDiscount;
    }

    getTaskList(serviceRequest: any): string {
        let taskList: string = '';
        for (let task of serviceRequest.service_request_tasks) {
            if (!task.main_task) {
                taskList += `${task.name}, `;
            }
        }
        return taskList.slice(0, -2);
    }

    async seesSpecialOffer(): Promise<void> {
        if (this.specialStillValid()) {
            let projects = await this.api.get("projects/v2");
            if (projects.length > 1) {
                let allDraft = projects.every((project: any) => project.status === "Draft");
                this.specialOfferValid = allDraft;
            } else if (projects.length === 1) {
                if (projects[0].status !== "Draft") {
                    this.specialOfferValid = false;
                } else {
                    this.specialOfferValid = true;
                }
            } else {
                this.specialOfferValid = false;
            }
        } else {
            this.specialOfferValid = false;
        }
    }

    specialActivated(): boolean {
        return this.serviceCounts && this.serviceCounts["Virtual Staging"] >= 12;
    }

    show12MonthsOfferDialog() {
        let options: any = {
            panelClass: 'twelve-months-offer-dialog',
            maxWidth: this.breakpointService.currentBreakpointClass === 'is-phone-portrait' ? '100vw' : '65rem',
        }
        this.dialog.open(this.twelveMonthsOfferDialog, options);
    }

    specialStillValid(): boolean {
        let regDate = moment.tz(this.user.created, 'America/Los_Angeles');

        let expiryDate = regDate.clone().add(2, 'days').startOf('day');

        let now = moment().tz('America/Los_Angeles');

        return now.isBefore(expiryDate);
    }

    getTimeUntilExpiry(): any {
        let regDate = moment.tz(this.user.created, 'America/Los_Angeles');

        let expiryDate = regDate.clone().add(2, 'days').startOf('day');

        let now = moment().tz('America/Los_Angeles');

        if (now.isAfter(expiryDate)) {
            return { hours: 0, minutes: 0 };
        } else {
            let duration = moment.duration(expiryDate.diff(now));
            let hours = Math.floor(duration.asHours());
            let minutes = duration.minutes();
            return {
                hours: hours,
                minutes: minutes,
            };
        }
    }

    getBumpZoneStyle() {
        const progress = this.totalProgress || 0;
        return {
            'width': `${progress}%`,
        }
    }

    floorplansTotal(): number {
        return this.floorplan2Requests.reduce((total: any, serviceRequest: any) => total + this.getTotalForRequest(serviceRequest), 0);
    }

    orderItems(): any {
        return this.project.service_requests;
    }

    async addFpBump(): Promise<void> {
        if (this.fpBump) {
            this.fpBump = false;
            await this.api.post(`project/${this.project.guid}/3d-floor-plan`, {
                is_order_bump: '0',
            })
            this.project.total -= this.fpBumpPrice;
            return;
        } else {
            this.fpBump = true;
            await this.api.post(`project/${this.project.guid}/3d-floor-plan`, {
                is_order_bump: '1',
            })
            this.project.total += this.fpBumpPrice;
            return;
        }
    }

    getFloorplan2Name(floorplanRequest: any): string {
        return "Floor plan " + (indexOf(this.floorplan2Requests, floorplanRequest) + 1);
    }

    checkFpBump(): void {
        if (this.project.floor_plan_token) {
            this.fpBump = true;
        } else {
            this.fpBump = false;
        }
    }

    openPhoneModal() {
        this.dialog.open(UpdatePhoneComponent, {
            panelClass: 'phone-modal'
        });
    }

    getExampleUrl(): string {
        return `${environment.app_url}p/lp/YmZkMjdiN2RmMzMzYWE4`;
    }

    showFpBump() {
        return this.serviceCounts["3D Floor Plan"] > 0 && this.serviceCounts["Virtual Staging"] >= 3;
    }

    displayTotal(): number {
        return Math.max(this.project.total - this.couponDiscount - this.vsCreditsApplied * 24 - this.walletFundsApplied, 0);
    }
}
