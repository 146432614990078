<div
    class="wrapper"
    *ngIf="project">
    <div
        class="summary"
        *ngIf="!ppCheckout">
        <div
            class="text"
            *ngIf="!isFp()">
            <h1>Select services</h1>
            <p>
                Click on the service below each image and tell us what edits you
                need for each photo.
            </p>
        </div>
        <div
            class="text"
            *ngIf="isFp()">
            <h1>Add Floor plan service</h1>
            <p>Click the button under the image and provide the details.</p>
        </div>

        <div class="photos">
            <button
                class="previous"
                [disabled]="totalImages === 1"
                (click)="slide(-1)">
                <mat-icon svgIcon="previous"></mat-icon>
            </button>
            <button
                class="next"
                [disabled]="totalImages === 1"
                (click)="slide(1)">
                <mat-icon svgIcon="next"></mat-icon>
            </button>
            <div
                class="add-more-wrapper"
                *ngIf="totalImages === 0">
                <div class="photo add-more">
                    <mat-icon
                        svgIcon="addImage"
                        *ngIf="!uploading" />
                    <p>Add an image</p>
                    <input
                        type="file"
                        (change)="onFileSelected($event)"
                        multiple
                        accept="image/x-png, image/gif, image/jpeg, image/png, image/jpg, image/webp, image/tiff, image/heic"
                        hidden
                        *ngIf="!uploading"
                        id="upload-images" />
                    <label
                        for="upload-images"
                        *ngIf="!uploading"></label>
                    <div
                        class="progress"
                        *ngIf="uploading">
                        <h1 class="progress-number">{{ totalProgress }}%</h1>
                        <div
                            class="progress-background"
                            [ngStyle]="getAddMoreStyle()"></div>
                    </div>
                </div>
            </div>
            <div
                style="display: contents"
                *ngIf="totalImages > 0">
                <div
                    class="service-request"
                    *ngFor="
                        let request of currentRequests;
                        let i = index;
                        trackBy: trackByFn
                    "
                    [class.single]="totalImages === 1"
                    [class.double]="totalImages === 2"
                    [@slideAnimation]="getState(i)">
                    <p class="order">
                        Image {{ request.name.split(" ")[1] }}
                        <span> of {{ this.totalImages }}</span>
                        <span>{{
                            request.total
                                ? "$" + request.total.toFixed(2)
                                : null
                        }}</span>
                    </p>
                    <div class="photo">
                        <mat-icon
                            svgIcon="greenCheckmark"
                            *ngIf="checkIfServiceRequestReady(request)"
                            class="ready" />
                        <img
                            [src]="getThumbnail(request)"
                            alt="uploaded image" />
                    </div>
                    <div
                        class="actions"
                        [class.hide]="request.id !== serviceRequest.id">
                        <input
                            type="file"
                            (change)="
                                onFileSelected($event); showUploadOverlay = true
                            "
                            multiple
                            accept="image/x-png, image/gif, image/jpeg, image/png, image/jpg, image/webp, image/tiff, image/heic"
                            hidden
                            *ngIf="!uploading"
                            id="upload-images" />
                        <label
                            class="upload-more"
                            for="upload-images"
                            >Upload more photos</label
                        >

                        <button
                            class="delete-photo"
                            (click)="deleteServiceRequest(request)">
                            Delete photo
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="services"
            *ngIf="totalImages > 0 && !isFp()">
            <ng-container *ngFor="let service of serviceTypes">
                <div
                    class="service"
                    (click)="selectService(service, $event)"
                    [class.selected]="service.selected"
                    [class.added]="service.added"
                    [class.disabled]="
                        (service.name !== 'Virtual Renovation' && vrAdded) ||
                        (service.name !== '3D Floor Plan' && fp2Added)
                    "
                    *ngIf="!service.disabled">
                    <mat-icon
                        *ngIf="!service.added"
                        [svgIcon]="service.icon" />
                    <mat-icon
                        *ngIf="service.added"
                        [svgIcon]="service.icon + 'Added'" />
                    <div class="name-and-price">
                        <p class="name">{{ service.name }}</p>
                    </div>
                    <mat-icon
                        *ngIf="service.added"
                        class="checkmark"
                        svgIcon="greenCheckmark" />
                </div>
            </ng-container>
        </div>
        <button
            class="add-fp-service"
            *ngIf="isFp() && getFpTask()"
            (click)="selectService(getFpTask(), $event)">
            {{
                !getFpTask().added
                    ? "Click to add required info"
                    : "Rquired info added"
            }}
            <mat-icon
                *ngIf="getFpTask().added"
                class="checkmark"
                svgIcon="greenCheckmark" />
        </button>
    </div>
    <div
        class="checkout"
        *ngIf="ppCheckout">
        <div class="order-details">
            <div
                class="announcements"
                *ngIf="!couponEnabled && !isFp()">
                <img
                    src="assets/bulk-discount.png"
                    alt="" />
                <div
                    class="discount-info"
                    *ngIf="seesBulkDiscount()">
                    <p>
                        Order virtual staging for 12 images and we'll stage 3
                        more for free. That's 15 images staged for only $19.20
                        per image.
                    </p>
                    <p>
                        If you need more than 15, each additional image will be
                        discounted at $19.
                    </p>
                </div>
                <div
                    class="special-offer"
                    *ngIf="specialOfferValid">
                    <h3>
                        <mat-icon svgIcon="specialOffer" />Limited time offer
                    </h3>
                    <p>
                        Order Virtual Staging for 12 photos or more and receive
                        two FREE virtual staging credits, which equals to two
                        virtually staged photos every month for the next 12
                        months ($576 value).
                        <br />
                        <br />
                        Simply place your order in the next
                        {{ getTimeUntilExpiry().hours }} hours and
                        {{ getTimeUntilExpiry().minutes }} minutes and the offer
                        will automatically activate.
                    </p>
                    <p>
                        <button (click)="show12MonthsOfferDialog()">
                            Click here
                        </button>
                        to learn the rules of the promotion.
                    </p>
                    <div
                        class="special-expiry"
                        *ngIf="!specialActivated()">
                        <p class="hours">{{ getTimeUntilExpiry().hours }}</p>
                        <p>Hrs</p>
                        <p class="minutes">
                            {{ getTimeUntilExpiry().minutes }}
                        </p>
                        <p>Mins</p>
                        <hr />
                    </div>
                    <p
                        class="special-activated"
                        *ngIf="specialActivated()">
                        <mat-icon svgIcon="blueCheckmark" />
                        ACTIVATED
                    </p>
                </div>
            </div>
            <div
                class="floorplan-bump"
                *ngIf="!isFp() && showFpBump()">
                <img
                    src="assets/interactive-landing-page.png"
                    alt="" />
                <div class="bump-info">
                    <p>
                        You can now add an interactive landing page with your
                        floor plan order.
                        <a
                            [href]="getExampleUrl()"
                            target="_blank"
                            >Click here</a
                        >
                        to see an example.
                        <br />
                        <br />
                        The landing page will feature your virtual staging
                        photos and floor plan.
                        <br />
                        <br />
                        The more photos you stage, the better the landing page
                        will look, with more position points on the floor plan.
                    </p>
                    <div
                        class="sign"
                        *ngIf="!isFp()">
                        <input
                            type="checkbox"
                            name="fp-bump"
                            [(ngModel)]="fpBump"
                            hidden
                            id="fp-bump" />
                        <button (click)="addFpBump()">
                            <span class="checkmark"
                                ><mat-icon
                                    svgIcon="blueCheckmark2"
                                    *ngIf="fpBump"
                            /></span>
                            Yes, I'd like an interactive landing page for ${{
                                fpBumpPrice
                            }}.
                        </button>
                    </div>
                </div>
            </div>
            <div class="header">
                <h1>Summary</h1>
                <h4>{{ project.name }}</h4>
            </div>
            <div class="pricing">
                <div class="details">
                    <div class="items">
                        <div
                            class="item"
                            *ngFor="let serviceRequest of orderItems()">
                            <div class="left">
                                <img
                                    [src]="getThumbnail(serviceRequest)"
                                    alt="uploaded image" />
                            </div>
                            <div class="right">
                                <div class="row-1">
                                    <h3
                                        class="name"
                                        *ngIf="!isFp()">
                                        {{ serviceRequest.name }}
                                    </h3>
                                    <h3
                                        class="name"
                                        *ngIf="isFp()">
                                        Floorplan
                                        {{ serviceRequest.name.split(" ")[1] }}
                                    </h3>
                                    <h3 class="price">
                                        {{
                                            serviceRequest.total === 0
                                                ? "FREE"
                                                : "$" +
                                                  serviceRequest.total?.toFixed(
                                                      2
                                                  )
                                        }}
                                    </h3>
                                </div>
                                <div class="row-2">
                                    <p
                                        *ngIf="
                                            serviceRequest.service_request_tasks
                                                .length > 0
                                        ">
                                        {{ getTaskList(serviceRequest) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="item"
                            *ngIf="fpBump">
                            <div class="right">
                                <div class="row-1">
                                    <h3 class="name">Interactive Floorplan</h3>
                                    <h3 class="price">
                                        ${{ fpBumpPrice.toFixed(2) }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div
                            class="coupon"
                            *ngIf="couponEnabled">
                            <div
                                class="not-applied"
                                *ngIf="!project.discount">
                                <h5>Have a Coupon Code?</h5>
                                <div class="input-wrapper">
                                    <input
                                        type="text"
                                        [(ngModel)]="couponCode"
                                        (keydown)="handleCouponKeydown($event)"
                                        name="coupon" />
                                    <button
                                        (click)="checkBeforeApplyingCoupon()">
                                        Apply
                                    </button>
                                </div>
                            </div>
                            <div
                                class="applied"
                                *ngIf="project.discount?.discount">
                                <h5>Coupon Applied</h5>
                                <p>
                                    {{ project.discount.name }}
                                    <span
                                        >-{{ project.discount.discount }}%</span
                                    >
                                </p>
                            </div>
                        </div>

                        <div
                            class="item discount"
                            *ngIf="totalDiscount > 0">
                            <p>
                                Discount:
                                <span> ${{ totalDiscount.toFixed(2) }}</span>
                            </p>
                        </div>
                        <div
                            class="item discount"
                            *ngIf="vsCreditsApplied > 0">
                            <p>
                                VS Credits:
                                <span> {{ vsCreditsApplied }}</span>
                            </p>
                        </div>
                        <div
                            class="item discount"
                            *ngIf="walletFundsApplied > 0">
                            <p>
                                Wallet funds:
                                <span>
                                    ${{ walletFundsApplied.toFixed(2) }}</span
                                >
                            </p>
                        </div>
                    </div>
                </div>
                <div class="total">
                    <h2>Total <span>(USD):</span></h2>
                    <h2>${{ displayTotal().toFixed(2) }}</h2>
                </div>
            </div>
            <p class="eta">
                <mat-icon svgIcon="clock" />Estimated Turnaround:
                {{
                    eta !== "Awaiting Review"
                        ? eta + "h"
                        : eta || "Calculating..."
                }}
            </p>
            <div class="enable-messages">
                <p>
                    Emails can sometimes be unreliable. If you don't receive any
                    email updates from us regarding your project, please log in
                    and check the status. Alternatively, you can
                    <button (click)="openPhoneModal()">click here</button>
                    to enable text message notifications.
                </p>
            </div>
        </div>
    </div>
</div>

<div
    class="upload-overlay"
    *ngIf="uploading && showUploadOverlay">
    <div class="progress">
        <h1 class="progress-number">{{ totalProgress }}%</h1>
        <div
            class="progress-background"
            [ngStyle]="getAddMoreStyle()"></div>
    </div>
</div>

<ng-template #12MonthsOfferDialog>
    <div class="special-offer-content">
        <button (click)="dialog.closeAll()">
            <mat-icon svgIcon="xMark" />
        </button>
        <h2>Free Virtual Staging for 12 Months</h2>
        <h5>Offer Details</h5>
        <p>
            Promotional credits will be added to your account every 30 days,
            aimed at enhancing your experience with virtual staging and
            demonstrating the long-term benefits.<br /><br />
            It's important to note that these credits do not accumulate and must
            be utilized within the 30-day cycle; any credits left unused at the
            end of each month will expire and cannot be carried over to the next
            period. The total value of this promotion is $576, assuming the
            credits are used each month.<br /><br />
            This offer is time-sensitive and expires in 27 hours and 13 minutes
            from now. Once you add Virtual Staging to 12 images or more, our
            system will notify you that the offer has been activated.<br /><br />
            This offer cannot be reactivated again. Take full advantage of this
            offer before it expires.
        </p>
    </div>
</ng-template>
