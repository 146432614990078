import { Component, Input, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/app/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { firstValueFrom } from 'rxjs';


@Component({
    selector: 'app-done',
    templateUrl: './done.component.html',
    styleUrls: ['./done.component.scss']
})
export class DoneComponent {
    public project: any;
    public user: any;
    public annotationsOverlayVisible: boolean = true;
    public reworkRequestError: boolean = false;
    public estimation: any;
    public remainingTime: any;
    public duration: any;
    @ViewChild('requestRevisionModal') requestRevisionModal: any;
    public starsHovered: number | null = 0;
    public showTrustpilot: boolean = false;
    public generatingZip: boolean = false;
    public requestingRework: boolean = false;
    public emailRequested: boolean = false;
    public mode: string = 'edit';
    public copiedToClipboard: boolean = false;
    @Input() articles: any[] = [];
    public displayRequests: any[] = [];

    constructor(
        private api: ApiService,
        public dialog: MatDialog,
        public breakpointService: BreakpointService
    ) { }

    ngOnInit() {
        switch (this.project.status_code) {
            case 'done':
                this.calculateProjectTime();
                break;
            case 'rework':
                this.calculateProjectTime();
                break;
            case 'in_progress':
                if (this.project.is_rework) {
                    this.calculateProjectTime();
                } else {
                    this.calculateRemainingTime();
                }
                break;
            default:
                this.calculateProjectTime();
        }
        this.calculateRemainingTime();
        this.displayRequests = this.serviceRequests();
    }

    getImageUrl(service: any) {
        if (service.request_image) {
            return environment.cdn + service.request_image.image_name;
        } else if (service.asset) {
            return service.asset.url;
        }
    }

    getFinalImageUrl(service: any) {
        if (service.final_image != undefined) {
            return environment.cdn + service.final_image.image_name;
        } else if (service.final_asset != undefined) {
            return service.final_asset.url;
        } else {
        }
    }

    getThumbnailFromUrl(url: string) {
        if (!url) {
            return "./assets/placeholder.png";
        }
        return environment.server + "/asset/thumbnail/" + url.split("/").slice(-1)[0];
    }

    getBefore(serviceRequest: any) {
        let url = environment.cdn + serviceRequest.asset.url;
        return environment.server + "/asset/thumbnail/" + url.split("/").slice(-1)[0];
    }

    getAfter(serviceRequest: any) {
        let url = environment.cdn + serviceRequest.final_asset.url;
        return environment.server + "/asset/thumbnail/" + url.split("/").slice(-1)[0];
    }

    async rateImage(rate: number, serviceRequest: any) {
        let rated = await this.api.patch(
            `project/${this.project.guid}/serviceRequest/${serviceRequest.id}/rate`,
            { rate }
        );
        if (rated) {
            serviceRequest.rate = rate;
        }
    }
    createImageUrl(project: any, file: string, index: number) {
        const link = project.name + "-image-" + index;
        let extension = "." + file.split(".").pop();

        return (
            encodeURI(
                link
                    .replace(/'/gi, "")
                    .replace(/\./gi, "")
                    .replace(/\,/gi, "")
                    .replace(/č|ć/gi, "c")
                    .replace(/ž/gi, "z")
                    .replace(/š/gi, "s")
                    .replace(/đ/gi, "dj")
                    .replace(/ +/g, "-")
                    .toLowerCase()
            ) + extension
        );
    }

    downloadFile(project: any, file: string, index: number) {
        let url;
        file = encodeURIComponent(file);
        url = environment.cdn + file;
        this.openWindowInSameTab(
            `${environment.downloadServer}download/file/streamed?url=${url}&filename=${file}&t=${new Date().getTime()}`
        );
    }

    downloadAsset(
        url: string,
        project_name: string,
        order: string,
        fileName: string,
        originalName: string
    ) {
        originalName = encodeURIComponent(originalName);
        this.openWindowInSameTab(
            `${environment.downloadServer}download/file/streamed?url=${url}&filename=${originalName}&t=${new Date().getTime()}`
        );
        return;
    }

    requestRevision(serviceRequest: any) {
        if (this.project.floor_plan_approved) {
            return;
        }
        serviceRequest.request_rework = {};
        serviceRequest.reference_assets = [];
        serviceRequest.request_rework.image_data = [];
        let dialogRef = this.dialog.open(this.requestRevisionModal, {
            panelClass: 'request-revision-modal',
            data: serviceRequest,
            maxWidth: '100vw',
        });
    }

    addReferenceImage(image: any, serviceRequest: any) {
        serviceRequest.reference_assets.push(image);
    }
    removeReferenceImage(id: number, serviceRequest: any) {
        serviceRequest.reference_assets = serviceRequest.reference_assets.filter((image: any) => image.id !== id);
    }

    handleAnnotationsChange(event: any, serviceRequest: any) {
        serviceRequest.request_rework.imageData = event;
    }

    hideAnnotationsOverlay() {
        this.annotationsOverlayVisible = false;
    }

    async requestRework(request: any) {
        this.requestingRework = true;
        if (
            request.request_rework &&
            ((request.request_rework.imageData &&
                request.request_rework.imageData.length) ||
                (request.request_rework.description &&
                    request.request_rework.description.length))
        ) {

            let assets: number[] = [];
            request.reference_assets.forEach((asset: any) => {
                assets.push(asset.id);
            });

            const rework = await this.api.post(
                `project/${this.project.guid}/serviceRequest/${request.id}/rework`,
                {
                    form: { data: JSON.stringify(request.request_rework) },
                    assets: JSON.stringify(assets),
                }
            );
            this.project.service_requests.splice(
                this.project.service_requests.indexOf(request),
                1,
                rework
            );

            this.dialog.closeAll();

            this.project.start_time = new Date();
            this.project.status_code = "rework";
            this.project.is_rework = true;
            this.requestingRework = false;

        } else {
            this.reworkRequestError = true;
            setTimeout(() => {
                this.reworkRequestError = false;
            }, 3000);
            this.requestingRework = false;
        }
    }

    reworkSubmitted(serviceRequest: any): boolean {
        return serviceRequest.status === 'Rework' ||
            serviceRequest.status === 'Rework Approved' ||
            serviceRequest.status === 'Approved';
    }

    closeDialog() {
        this.dialog.closeAll();
    }

    async leaveFeedback(feedback: any) {
        const newRating = await this.api.post(
            `project/${this.project.guid}/rating`,
            {
                rating: this.project.rated,
                description: feedback,
            }
        );
        this.project.rating = newRating;
        if (this.project.rating.rating >= 4) {
            this.showTrustpilot = true;
        }
        this.dialog.closeAll();
    }

    addWatermark(modal: any) {
        this.dialog.open(modal, {
            maxWidth: '100vw',
        });
    }

    closeWatermarkModal() {
        this.dialog.closeAll();
    }

    async downloadAll() {
        const dialogData = {
            icon: 'warning',
            message: `Would you like to compress your images as well?`,
            moreInfo: `The compression process will not change the resolution but will reduce the size of the images with minimal, if any, noticable loss in quality. This may take a few minutes.`,
            confirm: { title: `Download with compression` },
            reject: { title: `Download with no compression` },
            sameColorButtons: true,
            option: "Send files via email. You can safely leave the page and we'll email you when the download is ready",
        };
        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: dialogData,
            maxWidth: '100vw',
            disableClose: true,
        });

        confirmDialogRef.backdropClick().subscribe(() => {
            confirmDialogRef.close();
        });

        const response = await firstValueFrom(confirmDialogRef.afterClosed());
        let file: any;
        this.generatingZip = true;
        let options: any = {}
        if (response?.optionValue) {
            options.async = true;
        }
        if (response?.confirm === true) {
            file = await this.api.get(
                `project/${this.project.guid}/images/final?compress=true`, options)
        } else if (response?.confirm === false) {
            file = await this.api.get(
                `project/${this.project.guid}/images/final`, options)
        } else {
            this.generatingZip = false;
            return;
        }
        this.generatingZip = false;
        if (file === 200) {
            this.emailRequested = true;
            setTimeout(() => {
                this.emailRequested = false;
            }, 3000);
            return;
        }
        const url = environment.downloadServer + "download/" + file;
        const name = encodeURIComponent(this.project.name + ".zip");
        this.openWindowInSameTab(
            `${environment.downloadServer}download/file/streamed?url=${url}&filename=${name}&t=${new Date().getTime()}`
        );

    }

    openWindowInSameTab(url: string) {
        const newwindow = window.open(url, "_self");
        newwindow?.focus();
    }
    startLiveChat() {
        if (window.Intercom) {
            window.Intercom('show');
        }
    }

    calculateRemainingTime() {
        if (this.project.estimation_time) {
            const endDate: any = this.getEndDate(new Date(this.project.estimation_time));
            const currentTime: any = new Date();
            const duration = endDate - currentTime;

            const countdownEl = {
                hours: Math.floor(duration / (1000 * 60 * 60)),
                minutes: Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60))
            };

            const hours = countdownEl.hours.toString().padStart(2, '0');
            const minutes = countdownEl.minutes.toString().padStart(2, '0');

            if (duration >= 0) {
                this.remainingTime = hours + ":" + minutes;
                this.estimation = countdownEl.hours + " Hours " + countdownEl.minutes + " Minutes";
            } else {
                this.remainingTime = "Done";
                this.estimation = "Done";
            }
        } else {
            this.estimation = "In Review";
        }
    }

    getEndDate(estimation: any) {
        if (this.project.deadline) {
            return new Date(this.project.deadline);
        }
        const startDate = new Date(this.project.start_time);
        if (estimation instanceof Date) {
            return estimation;
        } else {
            return new Date(startDate.getTime() + estimation);
        }
    }

    calculateProjectTime() {
        const startDate: any = this.project.start_time_old
            ? new Date(this.project.start_time_old)
            : new Date(this.project.start_time);
        const finishTime: any = this.project.completition_time_old
            ? new Date(this.project.completition_time_old)
            : new Date(this.project.completition_time);

        const diffInMilliseconds = finishTime - startDate;
        const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const remainingMinutes = diffInMinutes % 60;

        const hours = diffInHours.toString();
        const minutes = remainingMinutes.toString();

        this.duration = {
            hours: hours.length < 2 ? "0" + hours : hours,
            minutes: minutes.length < 2 ? "0" + minutes : minutes,
        };
    }

    openIntercom() {
        if (window.Intercom) {
            window.Intercom('show');
        }
    }

    viewFloorPlan() {
        window.open(`${environment.app_url}p/lp/${this.project.floor_plan_token}`, '_blank');
    }

    approveFloorPlan() {
        let data = {
            title: 'Approve Floor Plan',
            message: 'Are you sure you want to approve the floor plan?',
            confirm: { title: 'Approve' },
            reject: { title: 'Cancel' },
        }
        this.dialog.open(ConfirmDialogComponent, {
            data,
            maxWidth: '100vw',
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.api.post(`project/${this.project.guid}/floorplan/toggle`, {
                    'floor_plan_approved': 1
                }).then(async () => {
                    let data = await this.api.get(`project/${this.project.guid}`);
                    this.project = data.project;
                    this.mode = data.mode;
                    this.calculateRemainingTime();
                    this.project.floorplan_status = 'IN_PROGRESS';
                })
            }
        });

    }

    shareFloorPlan() {
        if (navigator.share) {
            navigator.share({
                title: 'Virtual Staging Floor Plan',
                text: 'Check out the floor plan for this property',
                url: `${environment.app_url}p/lp/${this.project.floor_plan_token}`,
            }).catch((error) => {
                console.error('Error sharing floor plan', error);
            });
        } else {
            // Fallback: Copy to clipboard
            const url = `${environment.app_url}p/lp/${this.project.floor_plan_token}`;
            this.copyToClipboard(url);
        }
    }

    copyToClipboard(text: string) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.copiedToClipboard = true;
                    setTimeout(() => {
                        this.copiedToClipboard = false;
                    }, 2000);
                })
                .catch((error) => {
                    console.error('Error copying URL to clipboard', error);
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
                this.copiedToClipboard = true;
                setTimeout(() => {
                    this.copiedToClipboard = false;
                }, 2000);
            } catch (error) {
                console.error('Error copying URL to clipboard', error);
            }
            document.body.removeChild(textArea);
        }
    }


    serviceRequests(): any[] {
        let floorplanRequests = this.project.floorplan_requests?.map((request: any) => {
            let extraRequest = JSON.parse(JSON.stringify(request));
            extraRequest.is_ortho = true;
            return [request, extraRequest];
        });
        floorplanRequests = floorplanRequests?.flat() || [];
        return [...this.project.service_requests, ...floorplanRequests];
    }

    getOrthoImage(serviceRequest: any): string {
        let requestTask = serviceRequest.service_request_tasks.find((task: any) => task.name === "FP // Staging");
        if (requestTask) {
            return requestTask.max_ortho_render;
        } else {
            return '';
        }
    }

    downloadOrthoImage(serviceRequest: any) {
        let requestTask = serviceRequest.service_request_tasks.find((task: any) => task.name === "FP // Staging");
        if (requestTask) {
            let url = requestTask.max_ortho_render;
            this.openWindowInSameTab(
                `${environment.downloadServer}download/file/streamed?url=${url}&filename=${serviceRequest.final_asset.original_name}&t=${new Date().getTime()}`
            );
        }
    }

    needsApproval(): boolean {
        return (this.project.floorplan_requests && this.project.floorplan_requests.length > 0) && (this.project.service_requests && this.project.service_requests.length > 0);
    }
}
